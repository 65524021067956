import { siteConfig } from "../config/site";
import { Link } from "react-router-dom";
import { ArrowRight, Ban } from "lucide-react";
import React from "react";
import { Image } from "@nextui-org/react";
import CountdownToReset from "./countdown";

interface AdsProps {
  service: string;
  token: string;
  used_at: string;
}

interface AdsComponentProps {
  usedAds: AdsProps[];
}

export default function Ads({ usedAds }: AdsComponentProps) {
  return (
    <div className="md:w-2/4 w-4/4">
      {siteConfig.ads.map((ad, index) => {
        const usedAd = usedAds.find((usedAd) =>
          usedAd.service.toLowerCase().includes(ad.service.toLowerCase())
        );

        const isUsed = !!usedAd;

        return (
          <Link
            to={isUsed ? `` : `/ads/${index}`}
            key={index}
          >
            <div
              className={`rounded-md shadow-lg p-3 mb-3 w-13 mt-5 flex items-center border-back border-3 transition ease-in-out delay-50 hover:-translate-y-1 hover:border-primary hover:border-3 hover:scale-100 ${isUsed ? 'bg-red-500' : 'bg-back'}`}
            >
              <ad.icon size={35} />
              <div className="flex  ml-5">
                <h4 className="text-left mr-4 font-bold inline-flex items-center">
                  {ad.text} + {ad.credits}
                  <Image
                    src="/Images/costIcon.png"
                    className="inline-block w-6 h-6 ml-2"
                  />
                </h4>
                {isUsed && usedAd?.used_at && (
                  <div className="inline-block text-white">
                  <p>Time until reset</p>
                  <CountdownToReset currentTimestamp={usedAd.used_at} />
                  </div>
                )}
              </div>
              {isUsed ? <Ban className="ml-auto" /> : <ArrowRight className="ml-auto" />}
            </div>
          </Link>
        );
      })}
    </div>
  );
}
