import { useEffect, useState } from "react";
import { getStockValue } from "./api";

export default function ShowStock(props: { service: string }) {
  const [stockCounts, setStockCounts] = useState<{ [key: string]: number | string }>({});
  const [lastUpdate, setLastUpdate] = useState<string>("");

  useEffect(() => {
    const fetchStockCounts = async () => {
      try {
        const stockValue = await getStockValue(props.service);
        setStockCounts({ [props.service]: stockValue.stock });
        setLastUpdate(stockValue.lastUpdate.split("T")[0])
      } catch (error) {
        console.error("Error fetching stock count:", error);
        setStockCounts({ [props.service]: "Error" });
      }
    };

    fetchStockCounts();
  }, [props.service]);

  return (
    <>
      <p className="text-small text-orange-500">
        in stock: {stockCounts[props.service] ?? "Loading..."}
      </p>
    Last refill: {lastUpdate}
</>
);
}
