import React, { useState, useEffect } from 'react';

const CountdownToReset = ({ currentTimestamp }: { currentTimestamp: string }) => {
  const getTimeUntilReset = () => {
    const now: any = new Date();
    const submittedTime = new Date(currentTimestamp);
    const resetTime: any = new Date(submittedTime.getTime() + 24 * 60 * 60 * 1000); // 24 hours later
    return resetTime - now;
  };

  const [timeLeft, setTimeLeft] = useState(getTimeUntilReset());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeRemaining = getTimeUntilReset();
      setTimeLeft(timeRemaining);
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <div className="text-xl font-bold text-white">{formatTime(timeLeft)}</div>
    </div>
  );
};

export default CountdownToReset;
