import { useState, useEffect } from "react";
import { getCredits, updateCredits, addUserCredits } from "./api";
import Cookies from "js-cookie";
import { signal } from "@preact/signals";


export async function addUserId(newUserId: string) {
  try {
    await addUserCredits(newUserId, 0);
  } catch (error) {
    console.error("Error adding user ID:", error);
  }
}
