import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Image,
} from "@nextui-org/react";
import { PartyPopper } from "lucide-react";

export default function AlertModal() {
  const [addedValue, setAddedValue] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const addedParam = urlParams.get("added");

    // Überprüfe, ob der Parameter "added" existiert und setze den Wert in den State
    if (addedParam) {
      setAddedValue(addedParam);
      onOpen();
    }
  }, []);

  return (
    <Modal
      backdrop="opaque"
      isOpen={isOpen}
      onClose={onClose}
      className="bg-box"
    >
      <ModalContent className="text-white">
        <ModalBody className="flex flex-col items-center justify-center mt-6">
          <PartyPopper size={80} className="" />
          <p className="text-red-400 font-bold">You successfully earned:</p>

          <div className="flex items-center">
            {addedValue && (
              <>
                <p className="font-bold text-lg">{addedValue}</p>
                <Image src="/Images/costIcon.png" className="w-6 h-6 ml-2" />
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" variant="solid" onClick={onClose}>
            close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
