import { NextUIProvider } from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import { Youtube, Instagram } from "lucide-react"

import {
  TwitterIcon,
  GithubIcon,
  DiscordIcon,
  HeartFilledIcon,
  SearchIcon,
} from "../config/icons";
const Footer = () => {
  return (
    <footer className=" text-white py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center md:items-start mb-10">
          <img src="/images/logo.png" alt="Logo" className="h-30 mb-2" />
          <h1 className="text-lg font-bold">Silent-Gen.com</h1>
          <p className="text-gray-500">Copyright © 2024 Silent-Gen.com</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 text-center md:text-left">
          <div>
            <h2 className="font-bold mb-2">Pages</h2>
            <ul className="space-y-2">
              <li><Link href="/" >Home</Link></li>
              <li><Link href="/credits" >Credits</Link></li>
              <li><Link href="#" isDisabled={true} >Pro Generator (coming soon)</Link></li>
            </ul>
          </div>
          {/*<div>*/}
          {/*  <h2 className="font-bold mb-2">Generators</h2>*/}
          {/*  <ul className="space-y-2">*/}
          {/*    <li><Link href="#" >Cookie</Link></li>*/}
          {/*    <li><Link href="#" >Premium</Link></li>*/}
          {/*    <li><Link href="#" >Gamepass</Link></li>*/}
          {/*    <li><Link href="#" >Full Access</Link></li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          <div>
            <h2 className="font-bold mb-2">Legal</h2>
            <ul className="space-y-2">
              <li><Link href="/terms-of-service" >Terms of Service</Link></li>
              {/*<li><Link href="#" >Privacy Policy</Link></li>*/}
              {/*<li><Link href="#" >Disclaimer</Link></li>*/}
            </ul>
          </div>
          <div className="flex justify-center  space-x-4 mt-4 md:mt-0">
            <Link href="#" aria-label="Discord" >
              <DiscordIcon size={24} />
            </Link>
            <Link href="#" aria-label="YouTube" >
              <Youtube size={24} />
            </Link>
            <Link href="#" aria-label="Instagram" >
              <Instagram size={24} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
