// CreditsContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { getCredits, updateCredits } from "./api";
import Cookies from "js-cookie";

interface CreditsContextType {
  credits: number;
  updateCreditsHandler: (amount: number) => void;
}

const CreditsContext = createContext<CreditsContextType | undefined>(undefined);

export const useCreditsContext = (): CreditsContextType => {
  const context = useContext(CreditsContext);
  if (!context) {
    throw new Error("useCreditsContext must be used within a CreditsProvider");
  }
  return context;
};

interface CreditsProviderProps {
  children: ReactNode;
}

export const CreditsProvider: React.FC<CreditsProviderProps> = ({
  children,
}) => {
  const [credits, setCredits] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("userId");
        if (token) {
          const storedCredits = await getCredits(token);
          setCredits(storedCredits);
        }
      } catch (error) {
        console.error("Error fetching credits:", error);
      }
    };

    fetchData(); // Fetch credits when the component mounts

    return () => {
      // Cleanup function to avoid memory leaks
    };
  }, []);

  const updateCreditsHandler = async (amount: number) => {
    try {
      const token = Cookies.get("userId");
      const newCredits = credits + amount;
      if (token) {
        await updateCredits(newCredits, token);
        setCredits(newCredits);
      }
    } catch (error) {
      console.error("Error updating credits:", error);
    }
  };

  const contextValue: CreditsContextType = {
    credits,
    updateCreditsHandler,
  };

  return (
    <CreditsContext.Provider value={contextValue}>
      {children}
    </CreditsContext.Provider>
  );
};
