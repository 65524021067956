import React from "react";

const TermsOfService = () => (
  <section className="flex flex-col items-center pt-10 gap-4 min-h-screen px-4">
       <h1 className="mb-1 text-2xl mt-0 font-extrabold leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white text-center gap-5">
            Terms of Service
       </h1>
       <div className="lg:w-1/2 w-full max-w-screen-2xl text-center mx-auto bg-back rounded-xl p-6 ">
            <div>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Welcome to Silent-Gen.com! These terms of service ("Terms") govern your use of our website and services. By accessing or using Silent-Gen.com, you agree to be bound by these Terms.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Account Generation</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Silent-Gen.com provides a platform for account generation solely for personal use. Accounts generated through this website are not to be sold or distributed.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">User Responsibility</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      You agree not to engage in any illegal activities or misuse the accounts generated through Silent-Gen.com. You are solely responsible for the use of any account generated using this service.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Intellectual Property</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      All content, logos, and trademarks on Silent-Gen.com are the property of Silent-Gen and may not be used without explicit permission.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Limitation of Liability</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Silent-Gen.com and its affiliates are not liable for any consequences resulting from the use of accounts generated through this website.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Account Use</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Accounts generated through Silent-Gen.com are for personal use only. This means that you can use them on your own device. However, you are not allowed to sell, distribute, or rent accounts to others. This includes selling accounts on online marketplaces, trading accounts for other goods or services, or giving accounts away to friends or family members without their permission.
                 </p>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      If you are caught selling or distributing accounts, your Silent-Gen.com account will be terminated and all of your accounts will be banned. You may also be subject to legal action.
                 </p>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Please note that Silent-Gen.com is not affiliated with Mojang Studios or Minecraft. We are a third-party service that provides a convenient way to generate Minecraft accounts. By using our service, you agree to comply with the Minecraft End User License Agreement.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Account Sharing</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      You are not allowed to share accounts generated on Silent-Gen.com. Sharing your generated account could lead to your in-game account being banned on many Minecraft servers.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Refunds</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      All sales are final. There are no refunds for accounts or subscriptions generated through Silent-Gen.com because accounts are generated to order and cannot be reused.
                 </p>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      However, we may consider issuing a refund in certain circumstances, such as if you purchased an account and were unable to access it due to a technical issue. To request a refund, please contact Silent-Gen.com support.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Prohibited Uses</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      You are not allowed to use Silent-Gen.com to generate accounts for the purpose of cheating, hacking, or otherwise violating the Minecraft End User License Agreement. This includes using accounts to exploit bugs in the game or create or distribute malware.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Changes to the Terms of Service</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Silent-Gen.com reserves the right to modify or update these Terms of Service at any time without prior notice. Your continued use of the website constitutes acceptance of the modified Terms of Service.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Privacy Policy</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your personal information.
                 </p>

                 <h2 className="text-lg text-gray-300 font-bold text-center">Disclaimer</h2>
                 <p className="text-gray-400 text-base leading-relaxed mb-4">
                      Please also refer to our Disclaimer.
                 </p>
            </div>
       </div>
  </section>
);

export default TermsOfService;
