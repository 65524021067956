import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button,
} from "@nextui-org/react";
import { Preview } from "../components/preview";
import { Banner } from "../components/banner";
import Ip from "../components/ip";
import HowToUse from "../components/howToUse";
import Faq from "../components/faq";
import { useCredits } from "../components/SignalCreditsHandler";

const Home = () => {
  return (
    <>
      <section className="bg-back text-white py-20 px-4 sm:px-10 md:px-20 lg:px-60 h-[80vh] md:h-[50vh] flex justify-center items-center ">
        <div className="mx-auto flex flex-col md:flex-row items-center w-full ">
          <div className="mb-10 md:mb-0 md:mr-10">
            <h1 className="text-5xl font-bold mb-4">
              <span className="text-primary">Free</span> Account Generator
            </h1>
            <p className="text-md text-gray-500 font-bold mb-10">
              Get free accounts from several categories by completing simple steps
            </p>
            <Link
              href={"/generator"}
              className="font-bold py-4 px-7 bg-secondary rounded-md pulse-animation text-white hover:bg-opacity-85"
            >
              Free Accounts
            </Link>
          </div>
          <div className="m-auto w-auto overflow-auto">
            <img src="/Images/test.png" alt="Description of the image" className="max-w-[400px] bg-blue rounded-xl h-auto" />
          </div>
        </div>
      </section>
      <HowToUse />
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-10 mt-10 text-center">
            Frequently Asked Questions
          </h2>

          <Faq />
        </div>
    </>
  );
};

export default Home;
