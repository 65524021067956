import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Navbar from "./components/navbar";
import { NextUIProvider, Spinner } from "@nextui-org/react";
import "./input.css";
import { addUserId } from "./components/credits";
import Credits from "./pages/Credits";
import AdsPage from "./pages/AdsPage";
import Cookies from "js-cookie";
import axios from "axios";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import DateiUploader from "./pages/upload";
import AlertModal from "./components/alertModal";
import { CreditsProvider } from "./components/CreditsContext";
import Generator from "./pages/Generator";
import Footer from "./components/footer";
import TermsOfService from "./pages/Terms-of-service";

// Define route component interface
interface RouteProps {
  path: string;
  component: React.FC;
}

// Sample route components (replace with your actual components)

const App: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null); // Initialisiert userId mit null
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      // Überprüfen, ob die userId im Cookie vorhanden ist
      const userIdFromCookie = Cookies.get("userId");

      if (userIdFromCookie) {
        setUserId(userIdFromCookie);
      } else {
        // Wenn userId nicht im Cookie vorhanden ist, generiere eine neue ID
        const newUserId = generateUserId();
        setUserId(newUserId);
        await addUserId(newUserId);
        // Speichere die neue userId im Cookie
        Cookies.set("userId", newUserId);
      }

      // Setze den Ladezustand auf false, wenn das Fenster vollständig geladen ist
      setLoading(false);
    };

    initializeApp();
  }, []);

  // Funktion zur Generierung einer zufälligen userId (vereinfacht)
  const generateUserId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  if (loading) {
    // Hier wird der NextUI Spinner als Ladebildschirm angezeigt
    return (
      <NextUIProvider navigate={navigate}>
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      </NextUIProvider>
    );
  }

  return (
    <NextUIProvider navigate={navigate}>
      <CreditsProvider>
        <main className="dark text-foreground bg-black">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/generator" element={<Generator />} />
            <Route path="/about" element={<About />} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/ads/:step" element={<AdsPage />} />
            <Route path="/upload" element={<DateiUploader />} />
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
          <Footer />
        </main>
      </CreditsProvider>
    </NextUIProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReactNotifications />
      <App />
      <AlertModal />
    </BrowserRouter>
  </React.StrictMode>,
);
