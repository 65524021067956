import { Accordion, AccordionItem } from "@nextui-org/react";
import React from "react";
import { siteConfig } from "../config/site";
import "../config/style.css"
export default function Faq() {
  return (
    <>
      <Accordion variant="shadow" className="rounded-md custom-accordion">
        {siteConfig.faq.map((item, index) => (
          <AccordionItem
            key={index}
            aria-label="FAQ"
            className="font-bold"
            title={item.title}
          >
            <p className="font-normal">{item.text}</p>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}
