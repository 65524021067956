import React, { useEffect, useState } from "react";
import { Card, CardBody, Image, useDisclosure } from "@nextui-org/react";
import GenerateModal from "./generateModal";
import { siteConfig } from "../config/site";
import ShowStock from "./showStock";
import { ArrowRight, CheckCircle, CreditCard, Gift, UserCheck } from "lucide-react";
import { getUsedAdsByToken } from "./api";

export const Preview = () => {
  const [selectedService, setSelectedService] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCardClick = (serviceName: string) => {
    setSelectedService(serviceName);
    onOpen();}

  return (
    <>
      <h1 className="mb-1 text-2xl mt-0 pt-10 font-extrabold leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white text-center">
        Generate <span className="text-primary">Free</span> Accounts
      </h1>
      <h3 className="text-md text-gray-500 font-bold text-center">
        Choose what account you want to get for FREE.
      </h3>

      <div className="sm:w-10/12 lg:w-8/12 bg-box w-full px-8 m-auto my-10 py-10 flex flex-col sm:flex-row justify-center gap-10 rounded-xl">
        <div className="w-full lg:w-6/12">
          {siteConfig.services.map((service) => (
            <Card
              key={service.name}
              className={"w-12/12 lg:w-10/12 bg-back mb-3 p-0 rounded-lg shadow-none border-back border-3 transition ease-in-out delay-50 hover:-translate-y-1 hover:border-primary hover:border-3  hover:scale-100"}
            >
              <CardBody className="hover:pointer relative" onClick={() => handleCardClick(service.name)}>
                <div className="flex items-center justify-between p-0">
                  <div className="flex items-center">
                    <Image
                      alt={service.name}
                      height="auto"
                      src={`/images/${service.name}.png`}
                      width="50px"
                      className="mr-4"
                    />
                    <div className="ml-5">
                      <h3 className="font-semibold text-white">
                        {service.name.toUpperCase()}
                      </h3>
                      <p className="text-gray-400">
                        {/*Last refill: {service.lastRefill}*/}
                      </p>
                      <ShowStock service={service.name} />
                    </div>
                  </div>
                  <div className="flex items-center flex-row">
                    <div className="bottom-3 right-5 absolute flex-row flex">
                      <p className="text-white font-bold" >
                        {siteConfig.requiredCredits[service.name]}
                      </p>
                      <Image
                        src="/images/costIcon.png"
                        className="w-6 h-6"
                      />
                    </div>
                    <div className="top-3 right-5 absolute flex-row flex">
                      <ArrowRight className="text-white ml-2" />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
        <div className="w-full lg:w-5/12 rounded-xl bg-back p-10 text-white">
          <h3 className="text-2xl font-bold text-center mb-4">How to get</h3>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-lg font-semibold mb-2 flex items-center">
              <Gift className="mr-2 text-white" />
              Step 1: Earn Credits
            </h4>
            <p>Complete tasks and earn credits for free accounts.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-lg font-semibold mb-2 flex items-center">
              <CheckCircle className="mr-2 text-white" />
              Step 2: Choose Account
            </h4>
            <p>Select the account you want from the available options.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-lg font-semibold mb-2 flex items-center">
              <CreditCard className="mr-2 text-white" />
              Step 3: Redeem Credits
            </h4>
            <p>Redeem your credits to get the chosen account.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-2 flex items-center">
              <ArrowRight className="mr-2 text-white" />
              Step 4: Enjoy
            </h4>
            <p>Enjoy your free account and explore its benefits!</p>
          </div>
        </div>
      </div>
      {selectedService && (
        <GenerateModal
          service={selectedService}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
