import { Store } from "react-notifications-component";

export function newError(message: string) {
  Store.addNotification({
    title: "Sorry!",
    message: `${message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 1000,
      onScreen: false,
    },
  });
}
export function newSuccess(message: string) {
  Store.addNotification({
    title: "Congrats!!",
    message: `${message}`,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}
export function Copyed(text: string) {
  Store.addNotification({
    message: `Data successfully copied!`,
    type: "info",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
}
