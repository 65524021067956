import { ChevronRight, ChevronDown } from "lucide-react";

const HowToUse = () => {
  return (
    <section className="bg-box text-white py-20">
      <div className="container mx-auto text-center w-11/12">
        <h2 className="text-4xl font-bold mb-4 text-blue-500">How to Get Free Accounts?</h2>
        <p className="text-lg mb-10">
          Getting free streaming accounts is really easy and just requires 3 steps.
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
          <StepCard
            stepNumber="1"
            title="Earn Credits"
            description="Complete tasks and earn credits for free accounts."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
            }
          />

          <ChevronRight size={40} className="hidden md:inline-block text-blue-500 bounce-horizontal" />
          <ChevronDown className="inline-block md:hidden h-10 w-10 text-blue-500" />

          <StepCard
            stepNumber="2"
            title="Choose Account"
            description="Select the account you want from the available options."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A12.063 12.063 0 0112 15c3.042 0 5.824 1.12 7.879 2.804M9 10l2 2 4-4m7 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            }
          />

          <ChevronRight size={40} className="hidden md:inline-block text-blue-500 bounce-horizontal" />
          <ChevronDown className="inline-block md:hidden h-10 w-10 text-blue-500" />

          <StepCard
            stepNumber="3"
            title="Redeem Credits"
            description="Redeem your credits to get the chosen account."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A12.063 12.063 0 0112 15c3.042 0 5.824 1.12 7.879 2.804M9 10l2 2 4-4m7 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            }
          />

          <ChevronRight size={40} className="hidden md:inline-block text-blue-500 bounce-horizontal" />
          <ChevronDown className="inline-block md:hidden h-10 w-10 text-blue-500" />

          <StepCard
            stepNumber="4"
            title="Enjoy"
            description="Enjoy your free account and explore its benefits!"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A12.063 12.063 0 0112 15c3.042 0 5.824 1.12 7.879 2.804M9 10l2 2 4-4m7 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            }
          />
        </div>
      </div>
    </section>
  );
};

const StepCard = ({ stepNumber, title, description, icon }: any) => (
  <div className="bg-gray-800 p-6 rounded-lg text-left shadow-lg hover:border-blue-500 border-2 border-transparent transition-all">
    <div className="flex items-center mb-4">
      <div className="bg-blue-600 text-white rounded-full p-3 mr-3">
        {icon}
      </div>
      <h3 className="text-xl font-bold">{title}</h3>
      <span className="ml-auto text-2xl text-gray-500 font-bold">{stepNumber}</span>
    </div>
    <p>{description}</p>
  </div>
);

export default HowToUse;
