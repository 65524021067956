import { Link, Route, useParams } from "react-router-dom";
import { siteConfig } from "../config/site";
import React, { useState } from "react";
import { ArrowRight } from "lucide-react";
import {
  Accordion,
  AccordionItem,
  Button,
  Image,
  Spinner,
} from "@nextui-org/react";
import Faq from "../components/faq";
import { generateShortLink } from "../components/api";
import { newError } from "../components/notifcation";

const AdsPage = () => {
  const { step } = useParams();
  const [loading, setLoading] = useState(false);

  if (!step) {
    return <>Not Found </>;
  }
  const ad = siteConfig.ads[parseInt(step)];

  async function getShortLink() {
    setLoading(true);
    try {
      await generateShortLink(ad.service);
      setLoading(false);
    } catch (e) {
      newError(" Error: " + e);
    }
  }
  return (
    <>
      <section className="flex flex-col items-center pt-10 gap-4  min-h-screen px-4">
        <div className="w-full max-w-screen-2xl text-left mx-auto">
          <div className="bg-box rounded-lg shadow-lg p-8 mb-10 w-full flex flex-col space-y-10 mt-10">
            <div className="w-full md:flex md:items-stretch md:space-x-10 space-y-10">
              <div className="md:w-1/2 md:mr-4">
                <h3 className="text-2xl text-white font-bold ">
                  Please watch the following ad in order to earn credits
                </h3>
                <p className="text-secondary">
                  You will automatically receive the credits after completing the ad step
                </p>
                <div className="w-full">
                  <Link to="" onClick={() => getShortLink()}>
                    <div className="bg-back rounded-md shadow-lg p-3 mb-3 w-13 mt-5 flex items-center border-back border-3 transition ease-in-out delay-50 hover:-translate-y-1 hover:border-primary hover:border-3 hover:scale-100">
                      <ad.icon size={35} />
                      <h4 className="text-left mr-4 ml-5 font-bold">{ad.text}</h4>
                      {loading ? (
                        <Spinner color={"white"} className="ml-auto" />
                      ) : (
                        <ArrowRight className="ml-auto" />
                      )}
                    </div>
                  </Link>
                </div>
                <h2 className="text-xl flex items-center">
                  <p className="flex items-center text-2xl">
                    You will get:<span className="font-extrabold text-primary pl-2">{ad.credits}{" "}</span>
                  </p>
                  <Image src="/Images/costIcon.png" className="inline-block w-8 h-8 ml-2" />
                </h2>
                {/*<h2 className="text-3xl font-bold mb-4 mt-5">Information</h2>*/}
                {/*<p className="text-lg">*/}
                {/*  You need Credits to generate accounts. You can earn Credits by watching ads.*/}
                {/*</p>*/}
              </div>
              <div className="md:w-1/2 mt-4 md:mt-0">
                <Faq />
              </div>
            </div>
            <hr></hr>
            <div className="w-full h-full flex justify-center items-center text-center">
              <div>
                <h2 className="text-3xl font-bold mb-10 mt-0">How to skip these ads?</h2>
                <iframe
                  height="315"
                  src="https://www.youtube.com/embed/WvQHbRYaCUE?si=Dw5gklpJ1BDYoEBu"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};
export default AdsPage;
