import { Preview } from "../components/preview";
import { Banner } from "../components/banner";
import React from "react";
import Ip from "../components/ip";

export default function Generator() {
  return (
    <>
      <div className="bg-black">
        <Preview />
        {/*<Banner />*/}
      </div>
    </>
  );
}
