import { Button, Card, CardBody } from "@nextui-org/react";
import HowToUse from "../components/howToUse";

export default function About() {
  return (
    <>
      <section className="bg-box text-white py-20 px-60 text-left">
        <div className="container mx-auto">
          <h1 className="text-5xl font-bold mb-4 ">Free Account Generator</h1>
          <p className="text-md text-[gray] font-bold mb-10">
            Get free accounts from several categories by completing simple steps
          </p>
          <Button
            radius="sm"
            className="font-bold py-6 px-8 bg-secondary pulse-animation"
          >
            Get Started
          </Button>
        </div>
      </section>
      <HowToUse />
    </>
  );
}
