import React, { useState, ChangeEvent } from "react";
import { siteConfig } from "../config/site";
import axios from "axios";
import { Button } from "@nextui-org/react";
import { newError, newSuccess } from "../components/notifcation";

function DateiUploader() {
  const [zeilenArray, setZeilenArray] = useState<string[]>([]);
  const [type, setType] = useState<string>("");

  const handleDateiUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const datei = event.target.files?.[0];
    if (!datei) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) return;
      const dateiInhalt = e.target.result.toString();
      const zeilen = dateiInhalt.split("\n");
      setZeilenArray(zeilen);
    };

    reader.readAsText(datei);
  };

  const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value);
  };

  const handleBestätigenClick = () => {
    if (!type || zeilenArray.length === 0) {
      console.error(
        "Bitte wählen Sie einen Dateityp und laden Sie eine Datei hoch."
      );
      return;
    }

    axios
      .post(`${siteConfig.backendApiUrl}/api/accounts`, {
        array: zeilenArray,
        type: type,
      })
      .then((response) => {
        newSuccess("Accounts erfolgreich hochegeladen!")
        console.log("API-Antwort:", response.data);
      })
      .catch((error) => {
        newError("Fehler beim hochladen der Accounts")
        console.error("Fehler beim API-Aufruf:", error);
      });
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-secondary shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Accounts hochladen</h2>
      <input
        type="file"
        onChange={handleDateiUpload}
        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 mb-4 focus:outline-none"
      />
      <div className="mb-4">
        <label htmlFor="type" className="block mb-2 text-sm font-medium ">
          Dateityp wählen:
        </label>
        <select
          id="type"
          value={type}
          onChange={handleTypeChange}
          className="block w-full px-3 py-2 text-sm border bg-white text-black border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Bitte wählen</option>
          {siteConfig.services.map((service) => {
            return (
            <option key={service.name} value={service.name}>{service.name}</option>
            )
          })}
        </select>
      </div>
      <Button onClick={handleBestätigenClick} className="w-full bg-primary text-white">
        Bestätigen
      </Button>
      <h3 className="text-lg font-medium mt-6">Inhalt der hochgeladenen Datei:</h3>
      <ul className="list-disc list-inside mt-2">
        {zeilenArray.map((zeile, index) => (
          <li key={index} className="text-sm text-gray-700">
            {zeile}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DateiUploader;
