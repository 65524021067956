import React, { useState } from "react";
import { Mail, KeyRound, Copy, ArrowRight } from "lucide-react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Spinner,
  Image,
} from "@nextui-org/react";

import { siteConfig } from "../config/site";
import { Copyed, newError, newSuccess } from "./notifcation";
import ShowStock from "./showStock";
import { useCreditsContext } from "./CreditsContext";
interface Props {
  service: string;
  isOpen: boolean; // Hinzufügen der isOpen-Prop
  onClose: () => void; // Hinzufügen der onClose-Prop
}

const GenerateModal: React.FC<Props> = ({ service, isOpen, onClose }) => {
  const { credits, updateCreditsHandler } = useCreditsContext();
  const [account, setAccount] = useState({
    email: "--generate-first",
    password: "--generate-first",
  });
  const [loading, setLoading] = useState(false);

  async function getAccount() {
    setLoading(true);
    try {
      // Überprüfen, ob der Benutzer genügend Credits für den Service hat
      const requiredCredits: any = siteConfig.requiredCredits[service];

      if (credits < requiredCredits) {
        //newError("Not enough credits to generate")
        setLoading(false);
        newError("Not enough credits to create");
        return;
      }

      // Wenn genügend Credits vorhanden sind, Konto generieren
      const response = await fetch(
        `${siteConfig.backendApiUrl}/api/accounts/?service=${service}`,
      );
      const data = await response.json();
      console.log(data)
      if (data.error) {
        newError("No accounts in stock");
        setLoading(false);
        return;
      } else {
        updateCreditsHandler(-requiredCredits);
      }
      newSuccess("Successfully generated Account");
      setAccount(data);
      setLoading(false);
    } catch (error) {
      newError(`other error ${error}`);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        size="3xl"
        isOpen={isOpen}
        onClose={onClose}
        backdrop="blur"
        className="bg-box"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <span className="text-white">{service.toUpperCase()} Generator</span>
            <div className=" text-white flex flex-row ">
              {siteConfig.requiredCredits[service]}{" "} <Image src="/Images/costIcon.png" className="inline-block w-6 h-6 ml-0" />
            </div>
          </ModalHeader>
          <ModalBody className="justify-start text-white">
            <p className="!text-blue-500 font-extrabold">Email</p>
            <div className="bg-back an rounded-md shadow-lg mb-3 w-13 mt-0 px-5 py-4  flex items-center ">
              <Mail
                size={25}
                className="cursor-pointer flex-shrink-0 mr-5 text-blue"
              />
              <p className="text-greyed">{account.email}</p>
              <Copy
                size={25}
                className="cursor-pointer flex-shrink-0 ml-auto text-blue"
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard
                      .writeText(account.email)
                      .then((r) => Copyed(account.email));
                  } else {
                    console.error(
                      "Clipboard API is not supported in this browser",
                    );
                  }
                }}
              />
            </div>
            <p className="!text-blue-500 font-extrabold">Password</p>
            <div className="bg-back an rounded-md shadow-lg px-5 py-4  mb-3 w-13 mt-0  flex items-center ">
              <Mail
                size={25}
                className="cursor-pointer flex-shrink-0 mr-5 text-blue"
              />
              <p className="text-greyed">{account.password}</p>
              <Copy
                size={25}
                className="cursor-pointer flex-shrink-0 ml-auto text-blue"
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard
                      .writeText(account.password)
                      .then((r) => Copyed(account.password));
                  } else {
                    console.error(
                      "Clipboard API is not supported in this browser",
                    );
                  }
                }}
              />
            </div>
            <Button
              color="default"
              className="w-full rounded-md py-5 font-extrabold"
              onClick={getAccount}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Generate Account"}
            </Button>
          </ModalBody>
          <ModalFooter className="flex justify-between text-white">
            <ShowStock service={service} />

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateModal;
