import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarItem,
  NavbarMenuItem,
  Button,
  Image,
} from "@nextui-org/react";
import { Link } from "@nextui-org/react";

import { siteConfig } from "../config/site";
import clsx from "clsx";

import {
  TwitterIcon,
  GithubIcon,
  DiscordIcon,
  HeartFilledIcon,
  SearchIcon,
} from "../config/icons";
import React, { useState } from "react";
import { useCreditsContext } from "./CreditsContext";

export default function Navbar() {
  const { credits, updateCreditsHandler } = useCreditsContext();
  const [activeItem, setActiveItem] = useState('/'); // Default active item
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavItemClick = (href: string) => {
    setActiveItem(href);
  };

  return (
    <NextUINavbar
      isBordered
      maxWidth="xl"
      position="sticky"
      disableAnimation={true}
      isMenuOpen={isMenuOpen} // state value
      onMenuOpenChange={setIsMenuOpen} // state setter function
      className="bg-back border-b border-transparent"
    >
      <NavbarBrand as="li" className="gap-3 max-w-fit">
        <NavbarItem>
          <Link color="foreground" href="/" className="font-bold text-white relative pr-6">
            SILENT-GEN
            <p className="absolute text-primary right-0 bottom-1">v1</p>
          </Link>
        </NavbarItem>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4 font-normal" justify="end" >
        {siteConfig.navItems.map((item) => (
            <NavbarItem data-active={item.href === activeItem}   key={item.href}           className={clsx({
              'after:bg-primary': item.href === activeItem
            })}>
              <Link color="foreground" className="hover:text-primary" href={item.href} onClick={() => handleNavItemClick(item.href)}>
                {item.label}
              </Link>
            </NavbarItem>
        ))}
      </NavbarContent>

      <NavbarContent
        className="hidden sm:flex basis-1/5 sm:basis-full"
        justify="end"
      >
        <NavbarItem className="hidden sm:flex gap-2">
          <Link isExternal href={siteConfig.links.discord} aria-label="Discord">
            <DiscordIcon className="text-default-500" />
          </Link>
        </NavbarItem>
        <NavbarItem className="hidden md:flex">
          <Button color="secondary" className="w-30 h-10 mr-4 font-bold text-white">
            Your Credits: {credits}{" "}
            <Image
              src="/Images/costIcon.png"
              className="inline-block w-6 h-6 ml-0 pl-0"
            />
          </Button>
          {/*<Button
            color="secondary"
            className="w-30 h-10 mr-4"
            onClick={() => updateCreditsHandler(10)}
          >
            +10{" "}
            <Image
              src="/Images/costIcon.png"
              className="inline-block w-6 h-6 ml-0"
            />
          </Button>{" "}*/}
        </NavbarItem>
      </NavbarContent>

      <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
        <NavbarMenuToggle className="text-white" />
      </NavbarContent>

      <NavbarMenu className="bg-current/10 mt-22 rounded-md">
        <div className="mx-4 mt-2 flex flex-col gap-2">
          {siteConfig.navMenuItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`} className="w-full">
              <Link color="secondary" href={item.href} size="lg" className="text-white" onClick={  () =>          setIsMenuOpen((prev) => !prev)}>
                {item.label}
              </Link>
            </NavbarMenuItem>
          ))}
          <Button color="secondary" className="w-30 h-10 mr-4 font-bold">
            Your Credits: {credits}{" "}
            <Image
              src="/Images/costIcon.png"
              className="inline-block w-6 h-6 ml-0"
            />
          </Button>
        </div>
      </NavbarMenu>
    </NextUINavbar>
  );
}
