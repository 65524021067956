import { Input, Spinner } from "@nextui-org/react";
import { siteConfig } from "../config/site";
import { ArrowRight } from "lucide-react";
import Ads from "../components/ads";
import { Button } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUsedAdsByToken } from "../components/api";
import CountdownToMidnight from "../components/countdown";
import CountdownToReset from "../components/countdown";

export default function Credits() {
  const [usedAds, setUsedAds] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getAds() {
      const data = await getUsedAdsByToken();
      console.log(data)
      if (data) {
        setUsedAds(data);
        setLoading(false)
      }
    }
    getAds();
  }, []);
  return (
    <>
      <section className="flex flex-col items-center pt-10 gap-4  min-h-screen px-4">
        <div className="w-full max-w-screen-2xl text-left mx-auto">
          <h1 className="mb-1 text-2xl mt-0  font-extrabold leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white text-center">
            Earn <span className="text-primary">Credits</span>
          </h1>
          <h3 className="text-md text-gray-500 font-bold text-center">
            You need credits to generate accounts.
          </h3>
          <div className="bg-box rounded-lg shadow-lg p-8 mb-10 w-full mt-10">
            <h2 className="text-2xl font-bold mb-4">
              Watch some ads to earn credits
            </h2>
            <h4 className="text-xl font-bold my-4 text-secondary">
              Recommended:
            </h4>
            {loading ? <Spinner /> :
              <Ads usedAds={usedAds} />
            }
            {/*<h2 className="text-3xl font-bold mb-4">Information</h2>*/}
            <p className="text-lg">
              You need Credits to generate accounts. You can earn Credits by
              watching ads.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
