import axios from "axios";
import Cookies from "js-cookie";
import { siteConfig } from "../config/site";
import { newError } from "./notifcation";

export async function getCredits(token: string) {
  try {
    const response = await axios.get(
      `${siteConfig.backendApiUrl}/api/users/credits/${token}`,
    );
    return response.data[0].credits.toFixed(1);
  } catch (error) {
    newError("Api Error:" + error);
    throw error;
  }
}
export async function getStockValue(service: string){
  try {
    const response = await axios.get(
      `${siteConfig.backendApiUrl}/api/accounts/stock/${service}`,
    );
    return {
      stock: response.data.stock[0].count,
      lastUpdate: response.data.stock[0].created_at
    }
    //return response;
  } catch (e) {
    newError(`Error getting ${service} stock amount`);
    throw new Error(`Error getting ${service} stock amount`);
  }
}

export async function updateCredits(newCredits: number, token: string) {
  try {
    console.log("update credits")
    await axios.post(`${siteConfig.backendApiUrl}/api/users/update-credits`, {
      credits: newCredits,
      token,
    });
  } catch (error) {
    newError("Api Error:" + error);
    throw error;
  }
}

export async function addUserCredits(token: string, creditsToAdd: number) {
  try {
    await axios.post(`${siteConfig.backendApiUrl}/api/users/add-credits`, {
      credits: creditsToAdd,
      token,
    });
  } catch (error) {
    newError("Api Error:" + error);
    throw error;
  }
}
export const generateShortLink = async (service: string) => {
  try {
    console.log("aufgerufen");
    const userId = Cookies.get("userId"); // Annahme: Die userId wird im Frontend gesetzt
    const response = await axios.post(
      `${siteConfig.backendApiUrl}/api/shortener/shorten-link`,
      {
        service: service,
        userId: userId,
      },
    );
    const shortLink = response.data.shortUrl;
    if (shortLink) {
      window.location.href = shortLink;
    } else {
      newError(response.data.error)
    }
  } catch (error) {
    newError("Api Error:" + error);
    console.error("Error generating short link:", error);
  }
};
export const getUsedAdsByToken = async () => {
  try {
    const userId = Cookies.get("userId");
    const response = await axios.get(
      `${siteConfig.backendApiUrl}/api/users/get-used-ads/${userId}`,
      {
        data: {
          userId: userId,
        }
      },
    );
    console.log(response.data)
    return response.data
  } catch (e) {

  }
}
