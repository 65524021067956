import {
  Video,
  Book,
  Download,
  CalendarDays,
  History,
  CodeXml,
  Eye,
} from "lucide-react";

export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: "SILENT-GEN Account Generator",
  description: "Make beautiful websites regardless of your design experience.",
  navItems: [
    {
      label: "Generator",
      href: "/generator",
    },
    {
      label: "Credits",
      href: "/credits",
    },
  ],
  navMenuItems: [
    {
      label: "Generator",
      href: "/generator",
    },
    {
      label: "Credits",
      href: "/credits",
    },
  ],
  links: {
    discord: "https://discord.gg/KSw6GgHj4w",
  },
  ads: [
    {
      text: "Shrinkme",
      credits: 0.5,
      icon: Video,
      button: "claim",
      service: "shrinkme",
    },
    {
      text: "shrinkearn",
      credits: 0.5,
      icon: CalendarDays,
      button: "claim",
      service: "shrinkearn",
    },
    {
      text: "shrtfly",
      credits: 0.5,
      icon: Book,
      button: "claim",
      service: "shrtfly",
    },
    {
      text: "linksly",
      credits: 0.4,
      icon: Download,
      button: "claim",
      service: "linksly",
    },
    {
      text: "clicksfly",
      credits: 0.4,
      icon: Download,
      button: "claim",
      service: "clicksfly",
    },
    {
      text: "Daily free credit",
      credits: 0.5,
      icon: CalendarDays,
      button: "claim",
      service: "daily",
    },
  ],
  backendApiUrl: "https://kraekel.com",
  requiredCredits: {
    netflix: 1,
    disney: 2,
    spotify: 3,
    minecraft: 2,
    sky: 5,
    dazn: 1,
  } as Record<string, number>,
  services: [
    { name: "netflix", bg: "" },
    { name: "disney", bg: "" },
    { name: "spotify", bg: "green" },
    { name: "minecraft", bg: "yellow" },
    { name: "sky", bg: "red" },
    { name: "dazn", bg: "purple" },
  ],
  stats: [
    { title: "Customers", text: "over 3500", icon: History },
    { title: "New and innovative UI", text: "over 3500", icon: CodeXml },
    { title: "Daily Refills", text: "over 3500", icon: History },
    { title: "Generated Accounts", text: "over 3500", icon: History },
  ],
  faq: [
    {
      title: "How to skip the ads?",
      text: "Learn how to bypass advertisements with the video provided below. Additionally, stay tuned for the upcoming release of our Pro Version, which will offer enhanced features for generating multiple accounts.",
    },
    {
      title: "Why do some accounts not work?",
      text: "We are constantly striving to enhance our account checkers, but certain accounts may encounter issues due to factors such as two-factor authentication (2FA), bans, or recent password changes.",
    },
    {
      title: "Why do some accounts not work?",
      text: "We are constantly striving to enhance our account checkers, but certain accounts may encounter issues due to factors such as two-factor authentication (2FA), bans, or recent password changes.",
    },
    {
      title: "Why do some accounts not work?",
      text: "We are constantly striving to enhance our account checkers, but certain accounts may encounter issues due to factors such as two-factor authentication (2FA), bans, or recent password changes.",
    },
  ],
};
